/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ColumnsDataVisual {
    /**
    * Visual variant for column type.
    */
    'view'?: ColumnsDataVisualViewEnum;
    /**
    * Visual alignment for column.
    */
    'viewAlignment'?: ColumnsDataVisualViewAlignmentEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "view",
            "baseName": "view",
            "type": "ColumnsDataVisualViewEnum",
            "format": ""
        },
        {
            "name": "viewAlignment",
            "baseName": "viewAlignment",
            "type": "ColumnsDataVisualViewAlignmentEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ColumnsDataVisual.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ColumnsDataVisualViewEnum = "text" | "counter" | "checkbox" | "delete" | "input" | "rowSelect" ;
export type ColumnsDataVisualViewAlignmentEnum = "left" | "right" | "center" ;
