import React from "react";
import Select from "react-select";
import { AttributeConfigProps, EnumFieldKeys, SelectOption, ElementChangeFunction } from "../types/customTypes";
import { titleize } from "../common/helpers/string/titleize";
import AttributeStringField from "./AttributeStringField";

interface AttributeDropdownFieldProps {
  config: AttributeConfigProps;
  enumParam: EnumFieldKeys;
  enums: string[] | undefined;
  handleOnChange: ElementChangeFunction;
  showError: (attri: string) => boolean;
}
const AttributeDropdownField = ({
  enumParam,
  enums,
  handleOnChange,
  config,
  showError,
}: AttributeDropdownFieldProps): JSX.Element => {
  if (enums === undefined)
    return (
      <AttributeStringField
        key={`${config.type}_${enumParam.name}`}
        textBoxParams={enumParam.name}
        config={config}
        handleOnChange={handleOnChange}
        showError={showError}
      />
    );
  const enumMap = [
    ...enums.map((x) => {
      return { label: titleize(x), value: x };
    }),
    { label: "None", value: "" },
  ] as unknown as SelectOption[];
  return (
    <div data-testid="enum-select-component" className="flex-row p-2 text-xs font-normal">
      <h3 className="block text-gray-700 text-sm font-bold mb-2 capitalize">{titleize(enumParam.name)}</h3>
      <Select
        placeholder={`Select ${titleize(enumParam.name)}`}
        isSearchable={false}
        defaultValue={enumMap.find((item) => item.value === config[enumParam.name])}
        onChange={(item) =>
          handleOnChange({
            value: item?.value || "",
            key: enumParam.name,
            nodeType: config.type,
            commitToHistory: true,
          })
        }
        options={enumMap}
      />
    </div>
  );
};

export default AttributeDropdownField;
