/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SectionHeaderProperties } from './SectionHeaderProperties';
import { HttpFile } from '../http/http';

export class SelectionTableStepVisualProperties {
    /**
    * Determines whether this is last node of the page
    */
    'transition'?: boolean;
    /**
    * Visual variant for this step type.
    */
    'view'?: SelectionTableStepVisualPropertiesViewEnum;
    /**
    * Header properties to describe the section
    */
    'sectionHeader'?: SectionHeaderProperties;
    /**
    * Message to display when there are no elements in the list
    */
    'emptyTableMessage'?: string;
    /**
    * Message to display when there are no items in the list after filtering
    */
    'emptyFilterMessage'?: string;
    /**
    * Determines whether step complete on button click or user interaction
    */
    'manualComplete'?: string;
    /**
    * Manual complete button alignment
    */
    'manualCompleteAlignment'?: SelectionTableStepVisualPropertiesManualCompleteAlignmentEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "transition",
            "baseName": "transition",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "view",
            "baseName": "view",
            "type": "SelectionTableStepVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "sectionHeader",
            "baseName": "sectionHeader",
            "type": "SectionHeaderProperties",
            "format": ""
        },
        {
            "name": "emptyTableMessage",
            "baseName": "emptyTableMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "emptyFilterMessage",
            "baseName": "emptyFilterMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "manualComplete",
            "baseName": "manualComplete",
            "type": "string",
            "format": ""
        },
        {
            "name": "manualCompleteAlignment",
            "baseName": "manualCompleteAlignment",
            "type": "SelectionTableStepVisualPropertiesManualCompleteAlignmentEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SelectionTableStepVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type SelectionTableStepVisualPropertiesViewEnum = "default" | "reportTable" | "serviceSelection" ;
export type SelectionTableStepVisualPropertiesManualCompleteAlignmentEnum = "left" | "right" ;
