/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class EnablerVisualProperties {
    /**
    * Name of the icon
    */
    'icon'?: EnablerVisualPropertiesIconEnum;
    /**
    * Enabler modal box view size
    */
    'size'?: EnablerVisualPropertiesSizeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "icon",
            "baseName": "icon",
            "type": "EnablerVisualPropertiesIconEnum",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "EnablerVisualPropertiesSizeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return EnablerVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type EnablerVisualPropertiesIconEnum = "success" | "failure" | "alert" | "loading" | "print" ;
export type EnablerVisualPropertiesSizeEnum = "small" | "medium" | "large" ;
