/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class OptionsStepVisualProperties {
    /**
    * Determines whether this is last node of the page
    */
    'transition'?: boolean;
    /**
    * Visual variant for this step type.
    */
    'view'?: OptionsStepVisualPropertiesViewEnum;
    /**
    * Visual display for this step type.
    */
    'layout'?: OptionsStepVisualPropertiesLayoutEnum;
    /**
    * Used to allow a user to choose option size
    */
    'size'?: OptionsStepVisualPropertiesSizeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "transition",
            "baseName": "transition",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "view",
            "baseName": "view",
            "type": "OptionsStepVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "layout",
            "baseName": "layout",
            "type": "OptionsStepVisualPropertiesLayoutEnum",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "OptionsStepVisualPropertiesSizeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OptionsStepVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type OptionsStepVisualPropertiesViewEnum = "default" | "radio" | "item" ;
export type OptionsStepVisualPropertiesLayoutEnum = "grid" | "list" ;
export type OptionsStepVisualPropertiesSizeEnum = "small" | "medium" | "large" ;
