/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { EnablerVisualProperties } from './EnablerVisualProperties';
import { HttpFile } from '../http/http';

/**
* Function that are triggered as part of journey step lifecycle.
*/
export class Enabler {
    /**
    * Identifier of the enabler that is used to store values in steps inputData.
    */
    'id': string;
    /**
    * Name of the known enabler.
    */
    'name': string;
    'action': string;
    'stepEvent'?: string;
    'params': any;
    'loadingMessage'?: string;
    'errorMessage'?: string;
    'cancelMessage'?: string;
    'retriable'?: boolean;
    /**
    * Is used to specify whether enabler has cancel capability
    */
    'cancelable'?: boolean;
    'visual'?: EnablerVisualProperties;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "action",
            "baseName": "action",
            "type": "string",
            "format": ""
        },
        {
            "name": "stepEvent",
            "baseName": "stepEvent",
            "type": "string",
            "format": ""
        },
        {
            "name": "params",
            "baseName": "params",
            "type": "any",
            "format": ""
        },
        {
            "name": "loadingMessage",
            "baseName": "loadingMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "errorMessage",
            "baseName": "errorMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "cancelMessage",
            "baseName": "cancelMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "retriable",
            "baseName": "retriable",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "cancelable",
            "baseName": "cancelable",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "EnablerVisualProperties",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Enabler.attributeTypeMap;
    }

    public constructor() {
    }
}
