/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ChoiceStepVisualProperties {
    /**
    * Determines whether this is last node of the page
    */
    'transition'?: boolean;
    /**
    * Base64 image or URL to an image
    */
    'imageData'?: string;
    /**
    * Used to display badge on Item
    */
    'badge'?: string;
    /**
    * Used to allow a user to choose between multiple possible choice views
    */
    'view'?: ChoiceStepVisualPropertiesViewEnum;
    /**
    * Used to allow a user to choose choice size
    */
    'size'?: ChoiceStepVisualPropertiesSizeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "transition",
            "baseName": "transition",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "imageData",
            "baseName": "imageData",
            "type": "string",
            "format": ""
        },
        {
            "name": "badge",
            "baseName": "badge",
            "type": "string",
            "format": ""
        },
        {
            "name": "view",
            "baseName": "view",
            "type": "ChoiceStepVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "ChoiceStepVisualPropertiesSizeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ChoiceStepVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ChoiceStepVisualPropertiesViewEnum = "primary" | "primary-disabled" | "secondary" | "secondary-disabled" | "tertiary" | "item" | "item-selected" | "item-disabled" | "link" | "card" | "card-disabled" ;
export type ChoiceStepVisualPropertiesSizeEnum = "extrasmall" | "small" | "medium" | "large" ;
