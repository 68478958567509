/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Enabler } from './Enabler';
import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { NodeWithDefaultvalues } from './NodeWithDefaultvalues';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { NodeWithValidations } from './NodeWithValidations';
import { SelectionStepVisualProperties } from './SelectionStepVisualProperties';
import { TypeSelectionData } from './TypeSelectionData';
import { TypeSelectionStep } from './TypeSelectionStep';
import { ValidationField } from './ValidationField';
import { HttpFile } from '../http/http';

/**
* Selection step allows the user to select one of many values by providing a list to select from. Data can be retrieved via enabler or defined in journey script.
*/
export class SelectionStep {
    'type': TypeSelectionStep;
    'title'?: string;
    'description'?: string;
    /**
    * Selection step visual properties allows to provide visual settings, it includes transition and view type.
    */
    'visual'?: SelectionStepVisualProperties;
    /**
    * Holds the default selected data
    */
    'defaultSelected'?: TypeSelectionData;
    'data': string | Array<TypeSelectionData>;
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };
    /**
    * Used to ensure the data entered by a user is valid
    */
    'inputValidation'?: Array<ValidationField>;
    /**
    * Values that are used to pre-populate step with some initial data.
    */
    'defaultValues'?: { [key: string]: string; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeSelectionStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "SelectionStepVisualProperties",
            "format": ""
        },
        {
            "name": "defaultSelected",
            "baseName": "defaultSelected",
            "type": "TypeSelectionData",
            "format": ""
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "string | Array<TypeSelectionData>",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "inputValidation",
            "baseName": "inputValidation",
            "type": "Array<ValidationField>",
            "format": ""
        },
        {
            "name": "defaultValues",
            "baseName": "defaultValues",
            "type": "{ [key: string]: string; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SelectionStep.attributeTypeMap;
    }

    public constructor() {
    }
}
