import React from "react";
import { Amplify } from "aws-amplify";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from "@aws-amplify/ui-react";
import Header from "./components/Header";
import awsExports from "./config/aws-exports";
import Dashboard from "./components/Dashboard";
import { useUserStatus } from "./common/hooks/useUserStatus";
import { signUpFields } from "./common/constants/awsSignUpFields";
import packageJson from "../package.json";

Amplify.configure(awsExports);

const JourneyBuilderCanvas = (): JSX.Element => {
  const isLoggedIn = useUserStatus();
  const { version } = packageJson as { version: string };

  return (
    <div className="flex flex-col h-screen">
      <Header isLoggedIn={isLoggedIn} />
      <AmplifyAuthenticator className="h-full overflow-hidden flex items-center justify-center">
        <AmplifySignUp slot="sign-up" usernameAlias="email" formFields={signUpFields} />
        <AmplifySignIn slot="sign-in" usernameAlias="email">
          <div slot="header-subtitle" className="absolute bottom-2 right-48">
            <p>Version {version}</p>
          </div>
        </AmplifySignIn>

        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          draggable={false}
          limit={2}
          role="alert"
          transition={Flip}
        />
        <Dashboard />
      </AmplifyAuthenticator>
    </div>
  );
};

export default JourneyBuilderCanvas;
