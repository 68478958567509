/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Enabler } from './Enabler';
import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { NodeWithValidations } from './NodeWithValidations';
import { OptionsData } from './OptionsData';
import { OptionsStepVisualProperties } from './OptionsStepVisualProperties';
import { TypeOptionsStep } from './TypeOptionsStep';
import { ValidationField } from './ValidationField';
import { HttpFile } from '../http/http';

/**
* Used to statically or dynamically generate a list of options. It allows user to select/choose one.
*/
export class OptionsStep {
    'type': TypeOptionsStep;
    'title'?: string;
    'description'?: string;
    /**
    * Allow to remove selection from the previously selected option.
    */
    'nullifiable'?: boolean;
    'data': string | Array<OptionsData>;
    'visual'?: OptionsStepVisualProperties;
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };
    /**
    * Used to ensure the data entered by a user is valid
    */
    'inputValidation'?: Array<ValidationField>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeOptionsStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "nullifiable",
            "baseName": "nullifiable",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "string | Array<OptionsData>",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "OptionsStepVisualProperties",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "inputValidation",
            "baseName": "inputValidation",
            "type": "Array<ValidationField>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OptionsStep.attributeTypeMap;
    }

    public constructor() {
    }
}
