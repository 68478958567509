import React from "react";
import { titleize } from "../common/helpers/string/titleize";
import { JSONObject, ToolTipData } from "../types/customTypes";
import NodeTooltipContentObject from "./NodeTooltipContentObject";
import NodeTooltipKeyValue from "./NodeTooltipKeyValue";

interface NodeTooltipContentProps {
  tooltipItem: JSONObject;
  tooltipData: ToolTipData;
}
const NodeTooltipContent = ({ tooltipItem, tooltipData }: NodeTooltipContentProps): JSX.Element => {
  return (
    <div key={String(tooltipItem[tooltipData.hoverKey])}>
      <p
        className="font-bold text-center"
        data-testid={`${tooltipData.configKey}-${String(tooltipItem[tooltipData.hoverKey])}-header`}
      >
        {titleize(tooltipData.configKey)}:{" "}
        {tooltipItem[tooltipData.hoverKey] && titleize(String(tooltipItem[tooltipData.hoverKey]))}
      </p>
      {Object.keys(tooltipItem).map((key) => {
        if (typeof tooltipItem[`${key}`] === "object")
          return <NodeTooltipContentObject nestedTooltipItem={tooltipItem} objectKey={key} key={key} />;
        return (
          <div key={key} data-testid={key}>
            <NodeTooltipKeyValue objectKey={key} objectValue={String(tooltipItem[`${key}`])} />
          </div>
        );
      })}
    </div>
  );
};

export default NodeTooltipContent;
