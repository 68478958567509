/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class PromptStepActionsVisualProperties {
    /**
    * Visual variant for this step type.
    */
    'view'?: PromptStepActionsVisualPropertiesViewEnum;
    /**
    * Action button size
    */
    'size'?: PromptStepActionsVisualPropertiesSizeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "view",
            "baseName": "view",
            "type": "PromptStepActionsVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "PromptStepActionsVisualPropertiesSizeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PromptStepActionsVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type PromptStepActionsVisualPropertiesViewEnum = "primary" | "secondary" | "link" ;
export type PromptStepActionsVisualPropertiesSizeEnum = "extrasmall" | "small" | "medium" | "large" ;
