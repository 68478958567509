import { Amplify, Auth } from "aws-amplify";
import { AxiosError } from "axios";
import {
  DEVICES_PATH,
  DEVICE_OTP_PATH,
  GET_JOURNEY_LIST_PATH,
  GET_JOURNEY_PATH,
  JourneyCategories,
  PREVIEW_DRAFT_JOURNEY_PATH,
  REQUEST_METHODS,
  SAVE_JOURNEY_PATH,
} from "../../constants/apiConst";
import awsDeviceAuth from "../../../config/aws-deviceAuth";
import awsExports from "../../../config/aws-exports";
import api from "./api";
import {
  ErrorResponse,
  GroupJourneys,
  IDevices,
  JourneyItem,
  JourneyListResponse,
  MinimumHeader,
  OTPResponse,
  SignInResponse,
} from "./types";
import { JNode } from "../../../types";
import { journeyFileNamer } from "../journeys/journeyFileNamer";

export async function authHeaders(bearerToken: string | null = null): Promise<MinimumHeader> {
  const accessToken = bearerToken || (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

export const authenticateDevice = (deviceID: string): Promise<string> => {
  return new Promise((resolve, rejects) => {
    Amplify.configure(awsExports);
    api<OTPResponse>(DEVICE_OTP_PATH, { method: REQUEST_METHODS.PUT, params: { id: deviceID } })
      .then((otpReponse) => {
        Amplify.configure(awsDeviceAuth);
        Auth.signIn(deviceID, otpReponse.data.otp)
          .then((jwtRes: SignInResponse) => {
            resolve(jwtRes.signInUserSession.idToken.jwtToken);
          })
          .catch((err: AxiosError<ErrorResponse>) => {
            rejects(err.response?.data.message);
          });
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        rejects(err.response?.data.message || err.message);
      });
  });
};

export const saveJourney = (
  deviceID: string,
  journeyName: string,
  content: string,
  isDraft = false
): Promise<string> => {
  return new Promise((resolve, rejects) => {
    authenticateDevice(deviceID)
      .then((response) => {
        Amplify.configure(awsExports);
        const formData = new FormData();
        const blob = new Blob([content], { type: "text/json" });
        const localJourneyName = journeyFileNamer(journeyName, isDraft);
        formData.append("journey", blob, localJourneyName);
        if (!isDraft) formData.append("name", localJourneyName);
        api(isDraft ? PREVIEW_DRAFT_JOURNEY_PATH : SAVE_JOURNEY_PATH, {
          params: formData,
          authToken: response,
          method: REQUEST_METHODS.POST,
        })
          .then(() => {
            resolve("success");
          })
          .catch((err: AxiosError<ErrorResponse>) => {
            rejects(err.response?.data.message);
          });
      })
      .catch((err: string) => {
        rejects(err);
      });
  });
};

export const getErrorMessage = (error: AxiosError<ErrorResponse>): string => {
  return error.response?.data.message || error.message;
};

export const getDevices = (): Promise<IDevices> => {
  return new Promise((resolve, rejects) => {
    Amplify.configure(awsExports);
    api<IDevices>(DEVICES_PATH, { method: REQUEST_METHODS.GET })
      .then((devicesResponse) => {
        if (!devicesResponse.data.devices) {
          const error: Error = new Error("No Device");
          rejects(error);
        }
        resolve(devicesResponse.data);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        rejects(getErrorMessage(err));
      });
  });
};
export const getJourneys = (deviceID: string, removeJourneyList = false): Promise<Array<GroupJourneys>> => {
  return new Promise((resolve, rejects) => {
    authenticateDevice(deviceID)
      .then(() => {
        api<JourneyListResponse>(`${GET_JOURNEY_LIST_PATH}`, { method: REQUEST_METHODS.GET })
          .then((devicesResponse) => {
            if (!devicesResponse.data) {
              const error: Error = new Error("No Journey Found");
              rejects(error);
            }
            resolve(parseJourneysList(devicesResponse.data, deviceID, removeJourneyList));
          })
          .catch((err: Error) => {
            rejects(err);
          });
      })
      .catch((err: Error) => {
        rejects(err);
      });
  });
};
export const getJourney = (journeyName: string, category: string): Promise<JNode> => {
  return new Promise((resolve, rejects) => {
    api<JNode>(`${String(GET_JOURNEY_PATH)}?name=${journeyName}&category=${category}`, {
      method: REQUEST_METHODS.GET,
    })
      .then((devicesResponse) => {
        if (!devicesResponse.data) {
          const error: Error = new Error("No Journey Found");
          rejects(error);
        }
        resolve(devicesResponse.data);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        rejects(err);
      });
  });
};

export const parseJourneysList = (
  subJourneysResponse: JourneyListResponse,
  deviceId: string,
  removeJourneyList = false
): Array<GroupJourneys> => {
  const journeysList: GroupJourneys[] = [];
  let defaultJourneys: JourneyItem[] = [];
  let defaultSubJourneys: JourneyItem[] = [];
  let deviceJourneys: JourneyItem[] = [];
  let deviceSubJourneys: JourneyItem[] = [];
  if (subJourneysResponse.defaultJourneys?.length > 0 && !removeJourneyList) {
    defaultJourneys = subJourneysResponse.defaultJourneys.map((name: string) => {
      return {
        name,
        displayName: `${name} (J)`,
        key: `${name}-J-${JourneyCategories.DEFAULT_JOURNEYS}`,
        category: JourneyCategories.DEFAULT_JOURNEYS,
        categoryTitle: "DEFAULT",
      };
    });
  }
  if (subJourneysResponse.defaultSubJourneys?.length > 0) {
    defaultSubJourneys = subJourneysResponse.defaultSubJourneys.map((name: string) => {
      return {
        name,
        displayName: `${name} (SJ)`,
        key: `${name}-SJ-${JourneyCategories.DEFAULT_SUB_JOURNEYS}`,
        category: JourneyCategories.DEFAULT_SUB_JOURNEYS,
        categoryTitle: "DEFAULT",
      };
    });
  }
  if (defaultJourneys.length > 0 || defaultSubJourneys.length > 0)
    journeysList.push({ label: "DEFAULT", options: [...defaultJourneys, ...defaultSubJourneys] });

  if (subJourneysResponse.deviceJourneys?.length > 0 && !removeJourneyList) {
    deviceJourneys = subJourneysResponse.deviceJourneys.map((name: string) => {
      return {
        name,
        displayName: `${name} (J)`,
        key: `${name}-J-${JourneyCategories.DEVICE_JOURNEYS}`,
        category: JourneyCategories.DEVICE_JOURNEYS,
        categoryTitle: deviceId,
      };
    });
  }

  if (subJourneysResponse.deviceSubJourneys?.length > 0) {
    deviceSubJourneys = subJourneysResponse.deviceSubJourneys.map((name: string) => {
      return {
        name,
        displayName: `${name} (SJ)`,
        key: `${name}-SJ-${JourneyCategories.DEVICE_SUB_JOURNEYS}`,
        category: JourneyCategories.DEVICE_SUB_JOURNEYS,
        categoryTitle: deviceId,
      };
    });
  }
  if (deviceJourneys.length > 0 || deviceSubJourneys.length > 0)
    journeysList.push({ label: deviceId, options: [...deviceJourneys, ...deviceSubJourneys] });

  return journeysList;
};
